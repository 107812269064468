import React, { useEffect, useState } from "react";
import UseTable from "../../../useHook/useTable/UseTable";
import { useDispatch, useSelector } from "react-redux";
import { categoryData } from "./components/categoryData";
import { columnsTableCounterparty } from "./components/columns";
import { getSettingByType } from "../../../store/slices/settingSlice";

const Setting = () => {
  const dispatch = useDispatch();
  const selectedCategory = useSelector((state) => state.table.selectedCategory);
  const tableData = useSelector((state) => state.setting.data);
  const totalPages = useSelector((state) => state.setting.total);

  // pagination
  const [PageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    if (categoryData.find(({ id }) => id === selectedCategory?.id)) {
      dispatch(
        getSettingByType({
          type: selectedCategory?.id,
          offset: currentPage === 1 ? 0 : currentPage * PageSize - PageSize,
        })
      );
    }
  }, [selectedCategory, currentPage]);

  //  state filters
  const [dataInput, setDataInput] = useState({
    driver_name: "",
  });
  // input onChannge
  const onChangeData = (name, data) => {
    setDataInput((dataInput) => ({
      ...dataInput,
      [name]: data,
    }));
  };

  return (
    <UseTable
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      PageSize={PageSize}
      totalPages={totalPages}
      table_nav={true}
      data_nav={categoryData}
      data={tableData}
      columns={columnsTableCounterparty}
      onChangeData={onChangeData}
      dataInput={dataInput}
      tableNavLebel={"Опції"}
      addLink={{ to: "/setting/create", label: "Додати запис" }}
      movedButton={false}
    />
  );
};

export default Setting;
