import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UseModal from "../../../../useHook/UseModal";
import { getOrderInvoiceByTypeAndDate } from "../../../../store/slices/tableSlice";
const ModalOrder = ({ sellOrder }) => {
  const dispatch = useDispatch();
  const dateState = useSelector((state) => state.table.date);
  const data = useSelector((state) => state.table.orderByDate);

  const [modalCreate, setModalCreate] = useState({
    visible: false,
    item: null,
  });

  const closeModal = () =>
    setModalCreate(() => {
      return {
        item: null,
        visible: false,
      };
    });

  const onModal = () => {
    setModalCreate(() => {
      return {
        item: null,
        visible: true,
      };
    });
  };

  return (
    <>
      <div
        className={`table_button`}
        onClick={() => {
          dispatch(
            getOrderInvoiceByTypeAndDate(
              `type=${sellOrder}&minDate=${dateState.dateMin}&maxDate=${dateState.dateMax}&payment_type=cash`
            )
          );

          onModal();
        }}
      >
        <span>Звіт</span>
      </div>
      <UseModal
        visible={modalCreate.visible}
        title={`Звіт ${
          dateState && `${dateState.dateMin} - ${dateState.dateMax}`
        }`}
        content={
          <div className="modalDelete_wrapper">
            <span style={{ margin: "0" }} className="modalDelete_promo">
              Борг: {data && data?.debt}$
            </span>
            <span style={{ margin: "0" }} className="modalDelete_promo">
              Готівка: {data && data?.cash}$
            </span>
            <span style={{ margin: "0" }} className="modalDelete_promo">
              Картка: {data && data?.card}$
            </span>
            <div className="modalDelete_buttonWrapper">
              <button
                className="modalDelete_cancel"
                style={{
                  color: "#fff",
                  backgroundColor: "#008947",
                  width: "100%",
                }}
                onClick={closeModal}
              >
                Добре
              </button>
            </div>
          </div>
        }
        onClose={closeModal}
      />
    </>
  );
};

export default ModalOrder;
