export const columnsTablePurchase = [
  {
    Header: "checkbox",
    accessor: "checkbox",
  },
  {
    Header: "Код",
    accessor: "id",
  },
  {
    Header: "Автор",
    accessor: "user_name",
  },
  {
    Header: "Покупець",
    accessor: "counterparty_name",
  },
  {
    Header: "Ціна",
    accessor: "price_sum",
  },
  {
    Header: "Дата",
    accessor: "date",
  },
  {
    Header: "Час",
    accessor: "hours",
  },
];
export const columnCreatePurchase = [
  {
    Header: "delete",
    accessor: "delete",
  },

  {
    Header: "Вибраний товар",
    accessor: "name",
  },
  {
    Header: "Додати",
    accessor: "toggle_q",
  },
  {
    Header: "Кількість",
    accessor: "quantity_application",
  },
];

export const columnDetailsPurchase = [
  {
    Header: "delete",
    accessor: "delete",
  },

  {
    Header: "Вибраний товар",
    accessor: "name",
  },
  // {
  //   Header: "Додати",
  //   accessor: "toggle_q",
  // },
  {
    Header: "Кількість",
    accessor: "quantity_application",
  },
];

export const paymentTypeData = [
  {
    id: "debt",
    name: "Борг",
    value: "debt",
  },
  {
    id: "cash",
    name: "Готівка",
    value: "cash",
  },
  {
    id: "card",
    name: "Картка",
    value: "card",
  },
];
