import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import ApplicationCatalogue from "../../../../useHook/useTable/components/applicationCatalogue/ApplicationCatalogue";
import button_back from "../../../../style/img/button_back.png";
import Exel from "../../../../saveExcel/Exel";
import Report from "./Report";
import {
  setCheckedPaymentType,
  updatePaymentTypeInvoice,
} from "../../../../store/slices/tableSlice";
import UseDropDown from "../../../../useHook/dropDown/UseDropDown";
import { paymentTypeData } from "./columns";

const SellDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const data = useSelector(
    (state) => state.table.checkboxForDetailsOrDelete
  )[0];

  const invoicesItems =
    data &&
    data.CounterpartyInvoicesItems.map(({ Product, quantity, name, price }) => {
      return {
        quantity_application: quantity,
        price_sum: (quantity * price).toFixed(3),
        name: Product?.name || name,

        ...Product,
        price,
      };
    });
  const invoicesCounterparty = data && data.Counterparty;

  // payment type

  const checkedPaymentType = useSelector(
    (state) => state.table.checkedPaymentType
  );
  const handlePaymentType = (item) => {
    dispatch(setCheckedPaymentType(item));
  };
  useEffect(() => {
    if (data) {
      const findType = paymentTypeData.find(
        ({ id }) => id === data.payment_type
      );
      if (findType) {
        handlePaymentType(findType);
      }
    }
  }, [data]);
  // useEffect(() => {
  //   console.log(data);
  //   if (data?.discount_value) {
  //     dispatch(setCheckedDiscount(data.discount_value));
  //   }
  // }, [data]);

  return (
    <div className="purchaseCreate">
      <div className="purchaseCreate_head">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              onClick={() => navigate("/sell")}
              src={button_back}
              alt="back"
            />
            <span>Заявка</span>
          </div>

          <div style={{ display: "flex", gap: "16px" }}>
            <Report
              data={invoicesItems}
              counterparty={invoicesCounterparty?.name || ""}
              typeSell={checkedPaymentType?.name || ""}
              number={data?.id || ""}
              date={data?.date || ""}
            />
            <Exel
              data={invoicesItems}
              counterparty={invoicesCounterparty?.name || ""}
              typeSell={checkedPaymentType?.name || ""}
              number={data?.id || ""}
              date={data?.date || ""}
            />
          </div>
        </div>
      </div>
      <Formik
        initialValues={{
          discount_value: data.discount_value,

          comment: data.comment,
        }}
        validationSchema={Yup.object({
          // password: Yup.string().required("Enter password!"),
          // login: Yup.string().required("Enter login!"),
        })}
        onSubmit={async (body) => {
          const putData = body;
          putData.payment_type = checkedPaymentType.id;
          putData.id = data.id;
          dispatch(updatePaymentTypeInvoice(putData));
        }}
      >
        <Form className="">
          <div className="catalogueCreate_input_top_wrapper">
            <div className="catalogueCreate_input_top">
              <div className="catalogueCreate_input_top_first">
                <div style={{ width: "25%" }}>
                  <span>Покупець</span>
                  <div style={{ display: "flex" }}>
                    <span className="purchaseCounterpary">
                      {invoicesCounterparty && invoicesCounterparty.name}
                    </span>
                  </div>
                </div>
                <div>
                  <span>Тип цін</span>
                  <Field disabled type="text" name="discount_value" />
                </div>

                <div>
                  <span>Тип оплати</span>
                  <UseDropDown
                    selectedItem={checkedPaymentType}
                    handleChange={handlePaymentType}
                    data={paymentTypeData}
                  />
                </div>

                {/* <ErrorMessage name="login" className="error " component="div" /> */}
              </div>
            </div>
            <div className="catalogueCreate_input_top_textarea">
              <span>Коментар</span>
              <Field disabled as="textarea" type="text" name="comment" />
            </div>
          </div>

          <ApplicationCatalogue
            handleCounterApplication={() => {}}
            onChangeApplicationQuantity={() => {}}
            cancelItemTableFunc={() => {}}
            checkedRow={invoicesItems}
            setcheckedRow={() => {}}
            styleTable={{
              minHeight: "calc(100vh - 345px)",
              maxHeight: "calc(100vh - 345px)",
              padding: "0",
            }}
            button={false}
            toggle={false}
          />

          <div className="purchaseCreate_buttonWrapper">
            <button className="catalogueCreate_submit" type="submit">
              Зберегти
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default SellDetails;
