import React, { useEffect, useState } from "react";
import UseTable from "../../../../useHook/useTable/UseTable";
import { useDispatch, useSelector } from "react-redux";
import { columnsTableCatalouge } from "./columns";
import { getCategoryTable } from "../../../../store/slices/tableSlice";
import { getProductsByCatagory } from "../../../../store/slices/productSlice";
import { makeUrl } from "../../../../useHook/useTable/components/makeUrl";
const CatalogueAddingProducts = ({ navigate }) => {
  const dispatch = useDispatch();
  const selectedCategory = useSelector((state) => state.table.selectedCategory);
  const categoryData = useSelector((state) => state.table.category);
  const tableData = useSelector((state) => state.product.products);
  const totalPages = useSelector((state) => state.product.totalProducts);
  //  state filters
  const [dataInput, setDataInput] = useState({
    code: "",
    name: "",
    price: "",
    measurement: "",
    vendor_code: "",
  });
  // pagination
  const [PageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    dispatch(getCategoryTable());
  }, []);
  // change in future versions
  function getAllCategoryIds(categories) {
    const ids = [];

    function collectIds(category) {
      ids.push(category.id);
      if (category.subMenu && category.subMenu.length > 0) {
        category.subMenu.forEach((subCategory) => collectIds(subCategory));
      }
    }

    categories.forEach((category) => collectIds(category));

    return ids;
  }
  useEffect(() => {
    if (getAllCategoryIds(categoryData).includes(selectedCategory?.id)) {
      dispatch(
        getProductsByCatagory({
          category: selectedCategory?.id,
          offset: currentPage === 1 ? 0 : currentPage * PageSize - PageSize,
          search: makeUrl(dataInput),
        })
      );
    }
  }, [selectedCategory, currentPage, dataInput]);

  // input onChannge
  const onChangeData = (name, data) => {
    setDataInput((dataInput) => ({
      ...dataInput,
      [name]: data,
    }));
  };

  return (
    <UseTable
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      PageSize={PageSize}
      table_nav={true}
      tableNavLebel={"Каталог"}
      data_nav={categoryData}
      data={tableData}
      columns={columnsTableCatalouge}
      totalPages={totalPages}
      onChangeData={onChangeData}
      dataInput={dataInput}
      applicationsCatalog={true}
      button={false}
      navigateToHomePage={navigate}
    />
  );
};

export default CatalogueAddingProducts;
