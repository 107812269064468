import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UseModal from "../../../../useHook/UseModal";
import { reportProduct } from "../../../../store/slices/productSlice";

const ModalReport = () => {
  const dispatch = useDispatch();
  const dateState = useSelector((state) => state.table.date);
  const data = useSelector((state) => state.product.reportProduct);
  const checkedProduct = useSelector(
    (state) => state.table.checkboxForDetailsOrDelete
  )[0];

  const [modalCreate, setModalCreate] = useState({
    visible: false,
    item: null,
  });

  const closeModal = () =>
    setModalCreate(() => {
      return {
        item: null,
        visible: false,
      };
    });

  const onModal = () => {
    setModalCreate(() => {
      return {
        item: null,
        visible: true,
      };
    });
  };

  useEffect(() => {
    if (checkedProduct) {
      dispatch(
        reportProduct({
          productId: checkedProduct.id,
          startDate: dateState.dateMin,
          endDate: dateState.dateMax,
        })
      );
    }
  }, [checkedProduct, dateState]);
  return (
    <>
      <div
        style={{ padding: "10px 16px" }}
        onClick={() => {
          onModal();
        }}
      >
        <span>Звіт</span>
      </div>
      <UseModal
        visible={modalCreate.visible}
        title={`Звіт ${
          dateState &&
          `${dateState.dateMin} - ${dateState.dateMax}  ${checkedProduct?.name}`
        }`}
        content={
          <div className="modalDelete_wrapper" style={{ zIndex: "9999" }}>
            {/* <div>
              {data &&
                data.map(({ counterparty, totalQuantity }) => (
                  <div key={counterparty}></div>
                ))}
            </div> */}
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px",
                      backgroundColor: "#f2f2f2",
                      textAlign: "left",
                    }}
                  >
                    Покупець
                  </th>
                  <th
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px",
                      backgroundColor: "#f2f2f2",
                      textAlign: "left",
                    }}
                  >
                    Кол-во
                  </th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map(({ counterparty, totalQuantity }) => (
                    <tr key={counterparty}>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                        {counterparty}
                      </td>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                        {totalQuantity}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="modalDelete_buttonWrapper">
              <button
                className="modalDelete_cancel"
                style={{
                  color: "#fff",
                  backgroundColor: "#008947",
                  width: "100%",
                }}
                onClick={closeModal}
              >
                Добре
              </button>
            </div>
          </div>
        }
        onClose={closeModal}
      />
    </>
  );
};

export default ModalReport;
