import React from "react";
import { useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createCounterpartiesPost } from "../../../../store/slices/counterpartySlice";
import button_back from "../../../../style/img/button_back.png";
// import "./catalogueCreate.css";
const CounterpartyCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedCategory = useSelector((state) => state.table.selectedCategory);

  return (
    <div className="catalogueCreate">
      <div className="catalogueCreate_head">
        <img
          onClick={() => navigate("/counterparty")}
          src={button_back}
          alt="back"
        />
        <span>Новий контрагент</span>
      </div>
      <Formik
        initialValues={{
          name: "",
          full_name: "",

          view: "",
          code: "",
          TIN: "",
          identification_number: "",

          comment: "",
          // second
          EGRPOU: "",
          legal_address: "",
          post_address: "",
          phone: "",
        }}
        validationSchema={Yup.object({
          // password: Yup.string().required("Enter password!"),
          // login: Yup.string().required("Enter login!"),
        })}
        onSubmit={async (body) => {
          const data = body;
          data.type = selectedCategory.id;

          const req = await dispatch(createCounterpartiesPost(data));

          if (req.payload === 200) navigate("/counterparty");
        }}
      >
        <Form className="">
          <div className="catalogueCreate_input_top_wrapper">
            <div className="catalogueCreate_input_top">
              <div className="catalogueCreate_input_top_first">
                <div>
                  <span>Найменування</span>
                  <Field type="text" name="name" />
                </div>
                <div>
                  <span>Повне найменування</span>
                  <Field type="text" name="full_name" />
                </div>

                {/* <ErrorMessage name="login" className="error " component="div" /> */}
              </div>
              <div className="catalogueCreate_input_top_second">
                <div>
                  <span>Вид</span>
                  <Field type="text" name="view" />
                </div>
                <div>
                  <span>Код</span>
                  <Field type="text" name="code" />
                </div>
                <div>
                  <span>ИНН</span>
                  <Field type="text" name="TIN" />
                </div>
                <div>
                  <span>Номер свид.</span>
                  <Field type="text" name="identification_number" />
                </div>
              </div>
              <div
                style={{ marginTop: "30px" }}
                className="catalogueCreate_input_top_second"
              >
                <div>
                  <span>ЕГРПОУ</span>
                  <Field type="text" name="EGRPOU" />
                </div>
                <div>
                  <span>Юридический адрес</span>
                  <Field type="text" name="legal_address" />
                </div>
                <div>
                  <span>Почтовый адрес</span>
                  <Field type="text" name="post_address" />
                </div>
                <div>
                  <span>Телефон</span>
                  <Field type="text" name="phone" />
                </div>
              </div>
            </div>
            <div className="catalogueCreate_input_top_textarea">
              <span>Коментар</span>
              <Field as="textarea" type="text" name="comment" />
            </div>
          </div>

          {/* <div className="catalogueCreate_input_bottom_wrapper">
            <div className="catalogueCreate_input_bottom">
              <div>
                <span>НДС</span>
                <Field type="text" name="VAT" />
              </div>
              <div>
                <span>Пільга з НДС</span>
                <Field type="text" name="VAT_exemption" />
              </div>
              <div>
                <span>Кво для НН</span>
                <Field type="text" name="quo_for_NN" />
              </div>
              <div>
                <span>Торг націнки</span>
                <Field type="text" name="markup" />
              </div>
              <div>
                <span>Мінімальний залишок</span>
                <Field type="text" name="min_balance" />
              </div>
            </div>
          </div> */}

          <button className="catalogueCreate_submit" type="submit">
            Додати
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default CounterpartyCreate;
