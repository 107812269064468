import React, { useEffect, useState } from "react";
import UseTable from "../../../useHook/useTable/UseTable";
import { useDispatch, useSelector } from "react-redux";
import { columnsTablePayment } from "./components/columns";
import { categoryData } from "./components/categoryData";
import { makeUrl } from "../../../useHook/useTable/components/makeUrl";

// import axios, { API_URL } from "../../../axios";
// import { fetchEventSource } from "@microsoft/fetch-event-source";
import {
  setActiveCategory,
  getPayments,
  deletePaymentInvoice,
} from "../../../store/slices/paymentSlice";
const Payment = () => {
  const dispatch = useDispatch();
  const selectedCategory = useSelector((state) => state.table.selectedCategory);
  const totalPages = useSelector((state) => state.counterparty.totalInvoices);
  const tableData = useSelector((state) => state.payment.data);
  const dateState = useSelector((state) => state.table.date);
  //  state filters
  const [dataInput, setDataInput] = useState({
    id: "",
    user_name: "",
    counterparty_name: "",
  });
  // pagination
  const [PageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    dispatch(setActiveCategory(selectedCategory));
  }, [selectedCategory]);
  useEffect(() => {
    if (categoryData.find(({ id }) => id === selectedCategory?.id)) {
      dispatch(
        getPayments({
          type: selectedCategory?.id,
          offset: currentPage === 1 ? 0 : currentPage * PageSize - PageSize,
          search: makeUrl({ ...dataInput, ...dateState }),
        })
      );
    }
  }, [currentPage, selectedCategory, dataInput, dateState]);

  // input onChannge
  const onChangeData = (name, data) => {
    setDataInput((dataInput) => ({
      ...dataInput,
      [name]: data,
    }));
  };

  const deleteFunc = async (ids) => {
    const req = await dispatch(deletePaymentInvoice(ids));

    if (req.payload === 200) {
      dispatch(
        getPayments({
          type: selectedCategory?.id,
          offset: currentPage === 1 ? 0 : currentPage * PageSize - PageSize,
          search: makeUrl(dataInput),
        })
      );
    }
    return req;
  };
  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   const headers = {
  //     Authorization: `Bearer ${token}`,
  //   };
  //   const controller = new AbortController();
  //   const connectEventSource = async () => {
  //     console.log("podpiska: connect event source");
  //     await fetchEventSource(`${API_URL}/connect_payment`, {
  //       headers,
  //       signal: controller.signal,
  //       async onopen(response) {
  //         if (response.status === 401) {
  //           const response = await axios.get(`${API_URL}/refresh`, {
  //             withCredentials: true,
  //           });
  //           localStorage.setItem("token", response.data.acsses);
  //           // connectEventSource();
  //         }
  //       },
  //       onmessage() {
  //         console.log("work");
  //         if (categoryData.find(({ id }) => id === selectedCategory)) {
  //           dispatch(
  //             getPayments({
  //               type: selectedCategory,
  //               offset:
  //                 currentPage === 1 ? 0 : currentPage * PageSize - PageSize,
  //             })
  //           );
  //         }
  //       },
  //     });
  //   };
  //   connectEventSource();
  //   return () => {
  //     console.log("odpiska:  nahdhshds connect event source");
  //     controller.abort();
  //   };
  // }, [selectedCategory, currentPage]);
  return (
    <UseTable
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      PageSize={PageSize}
      totalPages={totalPages}
      tableNavLebel={"Каса"}
      table_nav={true}
      data_nav={categoryData}
      movedButton={false}
      data={tableData}
      columns={columnsTablePayment}
      onChangeData={onChangeData}
      dataInput={dataInput}
      addLink={{ to: "/payment/create", label: "Нова транзакція" }}
      // detailsLink={"/payment/details"}
      deleteFunc={deleteFunc}
      dateRange={true}
    />
  );
};

export default Payment;
