import React, { useState } from "react";

import remove_icon from "../../../style/img/remove_red_icon.png";

const BodyTable = ({
  data,
  columns,
  checkedRow,
  toggleCheckedChanged = () => {},
  handleCounterApplication,
  cancelItemTableFunc,
  onChangeApplicationQuantity,
}) => {
  // const dispatch = useDispatch();
  return (
    data &&
    data.map((item, index) => (
      <tr key={index} onClick={() => toggleCheckedChanged(item)}>
        {typeof item === "object" &&
          columns.map(({ accessor }, ind) => {
            if (accessor === "checkbox") {
              return (
                <td
                  key={accessor + ind}
                  className="tableBodyCheckboxWrapper"
                  style={{ cursor: "pointer", padding: "0px" }}
                >
                  <input
                    type="checkbox"
                    checked={!!checkedRow.find((check) => check.id === item.id)}
                    onChange={() => toggleCheckedChanged(item)}
                    className="chb chb-3"
                    id="chb-3"
                    style={{ width: "0px", height: "0px" }}
                  />
                  <label htmlFor="chb-3"></label>
                </td>
              );
            }

            if (accessor === "toggle_q") {
              return (
                <td key={index + ind}>
                  <div className="table_counter_application">
                    <span
                      onClick={() =>
                        handleCounterApplication({
                          accessor: "decrement",
                          id: item.id,
                        })
                      }
                    >
                      -
                    </span>
                    <span
                      onClick={() =>
                        handleCounterApplication({
                          accessor: "increment",
                          id: item.id,
                        })
                      }
                    >
                      +
                    </span>
                  </div>
                </td>
              );
            }
            if (accessor === "quantity_application") {
              return (
                <td key={index + ind}>
                  <input
                    className="applicationInput"
                    type="number"
                    value={item.quantity_application}
                    onChange={(e) => {
                      onChangeApplicationQuantity({
                        value: e.target.value,
                        id: item.id,
                      });
                    }}
                  />
                </td>
              );
            }
            if (accessor === "delete") {
              return (
                <th
                  key={index + ind}
                  onClick={() => cancelItemTableFunc(item.id)}
                  style={{ width: "50px", padding: "0" }}
                >
                  <img
                    style={{ margin: "0 auto" }}
                    src={remove_icon}
                    alt="delete"
                  />
                </th>
              );
            }
            // if (accessor === "price") {
            //   return (
            //     <th
            //       key={index + ind}
            //       onClick={() => cancelItemTableFunc(item.id)}
            //       style={{ width: "50px", padding: "0" }}
            //     >
            //       <img
            //         style={{ margin: "0 auto" }}
            //         src={remove_icon}
            //         alt="delete"
            //       />
            //     </th>
            //   );
            // }
            return <td key={index + ind}>{item[accessor]}</td>;
          })}
      </tr>
    ))
  );
};

export default BodyTable;
