import Excel from "exceljs";
import { saveAs } from "file-saver";

export const columnApplication = [
  {
    header: "Назва товару",
    key: "name",
  },
  {
    header: "Кількість",
    key: "quantity_application",
  },
  {
    header: "Ціна",
    key: "price",
  },
  {
    header: "Сума",
    key: "price_sum",
  },
];
// export const saveExcel = async ({
//   data,
//   columns = columnApplication,
//   counterparty,
//   typeSell,
//   number,
//   date,
// }) => {
//   const makeData = data.map((i, index) => {
//     return {
//       index: index + 1,
//       ...i,
//     };
//   });

//   const workSheetName = "Worksheet-1";
//   const workBookName = `Звіт ${date}`;
//   const workbook = new Excel.Workbook();

//   try {
//     const fileName = workBookName;

//     // creating one worksheet in workbook
//     const worksheet = workbook.addWorksheet(workSheetName);

//     // Supplier and Receiver Info
//     worksheet.addRow(["Постачальник:", "Дім фурнітури"]);
//     worksheet.addRow(["Отримувач:", counterparty]);
//     worksheet.addRow(["Вид торгівлі:", typeSell]);
//     worksheet.addRow([]);

//     // Document Title
//     worksheet.mergeCells("A4", "D4");
//     worksheet.getCell("A4").value = `Розхідна накладна № ${number} від ${date}`;
//     worksheet.getCell("A4").font = { bold: true, size: 16 };
//     worksheet.getCell("A4").alignment = { horizontal: "center" };

//     // Add empty row for spacing
//     worksheet.addRow([]);

//     // Add table header
//     const tableHeaderRow = worksheet.addRow(
//       columns.map((column) => column.header)
//     );
//     tableHeaderRow.font = { bold: true };

//     // Add table data
//     let totalSum = 0;
//     makeData.forEach((rowData) => {
//       const row = worksheet.addRow(
//         columns.map((column) => rowData[column.key])
//       );
//       row.eachCell({ includeEmpty: true }, (cell) => {
//         cell.border = {
//           top: { style: "thin" },
//           left: { style: "thin" },
//           bottom: { style: "thin" },
//           right: { style: "thin" },
//         };
//       });

//       // Add each row's total sum to the grand total
//       totalSum += rowData.price_sum;
//     });

//     // Add "Ітого" at the price column

//     worksheet.addRow([null, null, "Ітого:", totalSum.toFixed(2)]); // Выводим общую сумму

//     // Add total amount row
//     worksheet.addRow([]);
//     worksheet.addRow(["Всього на суму:", totalSum.toFixed(2)]); // Общая сумма еще раз

//     // Add note about returns and prices
//     worksheet.addRow([]);
//     worksheet.addRow([
//       "Повернення або обмін товару здійснюється тільки за наявності розхідної накладної",
//     ]);
//     worksheet.addRow([
//       "Ціни надані в рекламних цілях. Не є підставою для бухгалтерського обліку.",
//     ]);

//     // Signatures
//     worksheet.addRow([]);
//     worksheet.addRow(["Відвантажив(ла):", "", "Отримав(ла):", ""]);

//     // Format column widths
//     worksheet.columns.forEach((column) => {
//       column.width = 30;
//     });

//     // write the content using writeBuffer
//     const buf = await workbook.xlsx.writeBuffer();

//     // download the processed file
//     saveAs(new Blob([buf]), `${fileName}.xlsx`);
//   } catch (error) {
//     console.error("<<<ERROR>>>", error);
//     console.error("Something Went Wrong", error.message);
//   } finally {
//     // removing worksheet's instance to create new one
//     workbook.removeWorksheet(workSheetName);
//   }
// };

export const saveExcel = async ({
  data,
  columns = columnApplication,
  counterparty,
  typeSell,
  number,
  date,
}) => {
  const makeData = data.map((i, index) => {
    return {
      index: index + 1,
      ...i,
    };
  });

  const workSheetName = "Worksheet-1";
  const workBookName = `Звіт ${date}`;
  const workbook = new Excel.Workbook();

  try {
    const fileName = workBookName;

    // creating one worksheet in workbook
    const worksheet = workbook.addWorksheet(workSheetName);

    // Supplier and Receiver Info
    worksheet.addRow(["Постачальник:", "Дім фурнітури"]);
    worksheet.addRow(["Отримувач:", counterparty]);
    worksheet.addRow(["Вид торгівлі:", typeSell]);
    worksheet.addRow([]);

    // Document Title
    worksheet.mergeCells("A4", "D4");
    worksheet.getCell(
      "A4"
    ).value = `Видаткова накладна № ${number} від ${date}`;
    worksheet.getCell("A4").font = { bold: true, size: 16 };
    worksheet.getCell("A4").alignment = { horizontal: "center" };

    // Add empty row for spacing
    worksheet.addRow([]);

    // Add table header
    const tableHeaderRow = worksheet.addRow(
      columns.map((column) => column.header)
    );
    tableHeaderRow.font = { bold: true };

    // Add table data
    let totalSum = 0;
    makeData.forEach((rowData) => {
      const row = worksheet.addRow(
        columns.map((column) => rowData[column.key])
      );
      row.eachCell({ includeEmpty: true }, (cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });

      // Add each row's total sum to the grand total
      totalSum += rowData.price_sum;
    });

    // Add "Ітого" at the price column
    worksheet.addRow([null, null, "Ітого:", totalSum.toFixed(2)]); // Выводим общую сумму

    // Add total amount row
    worksheet.addRow([]);
    worksheet.addRow(["Всього на суму:", totalSum.toFixed(2)]); // Общая сумма еще раз

    // Add note about returns and prices
    worksheet.addRow([]);
    worksheet.addRow([
      "Повернення або обмін товару здійснюється тільки за наявності розхідної накладної",
    ]);
    worksheet.addRow([
      "Ціни надані в рекламних цілях. Не є підставою для бухгалтерського обліку.",
    ]);

    // Signatures
    worksheet.addRow([]);
    worksheet.addRow(["Відвантажив(ла):", "", "Отримав(ла):", ""]);

    // Format column widths

    worksheet.getColumn(1).width = 100;
    worksheet.getColumn(2).width = 10;
    worksheet.getColumn(3).width = 10;
    worksheet.getColumn(4).width = 10;

    // write the content using writeBuffer
    const buf = await workbook.xlsx.writeBuffer();

    // download the processed file
    saveAs(new Blob([buf]), `${fileName}.xlsx`);
  } catch (error) {
    console.error("<<<ERROR>>>", error);
    console.error("Something Went Wrong", error.message);
  } finally {
    // removing worksheet's instance to create new one
    workbook.removeWorksheet(workSheetName);
  }
};
