// url for request
export const makeUrl = (dataInput) => {
  let ret = "";

  Object.entries(dataInput).map(([key, value]) => {
    ret += value.length > 0 ? "&" + key + "=" + value : "";
  });

  return ret;
};
