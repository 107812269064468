import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  checkboxForDetailsOrDelete,
  setMovingChecked,
} from "../../store/slices/tableSlice";
import TableNav from "./components/tableNav/TableNav";
import HeaderTable from "./components/HeaderTable";
import BodyTable from "./components/BodyTable";
import Pagination from "../pagination/Pagination";
import ModalDelete from "./components/ModalDelete";
import ModalOrder from "../../components/PrivateRoutes/sell/components/ModalOrder";
import ModalReport from "../../components/PrivateRoutes/catalogue/components/ModalReport";
import DropDownCalendar from "../useCalendar/DropDownCalendar";
import ApplicationCatalogue from "./components/applicationCatalogue/ApplicationCatalogue";
import { NavLink, useNavigate } from "react-router-dom";
import { ReactComponent as SeeIcon } from "../../style/img/see_icon.svg";
import { ReactComponent as FolderIcon } from "../../style/img/folder_icon.svg";
import { ReactComponent as AddIcon } from "../../style/img/add_icon.svg";

import "./index.css";

const Table = ({
  // navigate
  table_nav = false,
  table_nav_search = false,
  data_nav,
  // data
  data,
  // main columns
  columns,
  //for Pagination
  totalPages,
  currentPage,
  setCurrentPage,
  PageSize,
  // for search
  onChangeData,
  dataInput,
  // lebel table
  tableNavLebel = "",
  // for Catalouge create categories
  createNewCategory = false,
  // for button
  button = true,
  addLink = false,
  detailsLink,
  deleteButton = true,
  movedButton = true,
  addCounerpartyButton = false,
  addCounerpartyLink = "",
  deleteFunc = () => {},
  dateRange = false,
  // secound table for adding products
  applicationsCatalog = false,
  // link home
  navigateToHomePage,
  //sell order
  sellOrder = false,
  catalogReport = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const checkedRowRedax = useSelector((state) => state.table.checkboxData);
  const movingChecked = useSelector((state) => state.table.movingChecked);

  // check active row
  const [checkedRow, setcheckedRow] = useState([]);
  // redux checkedData + table cheked data
  useEffect(() => {
    if (applicationsCatalog) {
      setcheckedRow((state) => [...state, ...checkedRowRedax]);
    }
  }, []);

  const checkedRowLength = checkedRow.length;

  const toggleCheckedChanged = (item) => {
    setcheckedRow((state) => {
      const findItem = state.find((checked) => checked.id === item.id);
      if (findItem) {
        return state.filter((checked) => checked.id !== item.id);
      } else {
        // add new property for application table
        const newItem = {
          ...item,
          quantity_application: 1,
          price_sum: item.price,
        };
        return [...state, newItem];
      }
    });
  };
  // toggle quantity application table
  const handleCounterApplication = ({ accessor, id }) => {
    setcheckedRow((state) => {
      const updatedState = state.map((item) => {
        if (item.id === id) {
          if (accessor === "increment") {
            return {
              ...item,
              quantity_application:
                (parseInt(item.quantity_application) || 0) + 1,
              price_sum:
                ((parseInt(item.quantity_application) || 0) + 1) * item.price,
            };
          } else if (accessor === "decrement") {
            return {
              ...item,
              quantity_application: Math.max(1, item.quantity_application - 1),
              price_sum:
                Math.max(1, item.quantity_application - 1) * item.price,
            };
          }
        }
        return item;
      });

      return updatedState;
    });
  };

  const onChangeApplicationQuantity = ({ value, id }) => {
    setcheckedRow((state) => {
      const updatedState = state.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            quantity_application: value,
            price_sum: value * item.price,
          };
        }
        return item;
      });

      return updatedState;
    });
  };
  const cancelCheckedDataById = (id) => {
    setcheckedRow((state) => state.filter((item) => item.id !== id));
  };

  const deleteTableFunc = (ids) => {
    setcheckedRow([]);
    return deleteFunc(ids);
  };
  if (currentPage - 1 > totalPages / PageSize) {
    setCurrentPage(1);
  }

  // request  excel
  const excel = (columns) => {
    // dispatch(
    //   getDataExsel({
    //     body: makeUrl(dataInput),
    //     limit: totalPages,
    //     columns,
    //   })
    // );
  };

  return (
    <div style={{ display: "flex", width: "100%" }}>
      {table_nav && (
        <TableNav
          data_nav={data_nav}
          tableNavLebel={tableNavLebel}
          createNewCategory={createNewCategory}
          table_nav_search={table_nav_search}
        />
      )}
      <div
        style={movingChecked ? { opacity: "0.1" } : {}}
        onClick={() => {
          if (movingChecked) {
            dispatch(setMovingChecked());
          }
        }}
        className="table_wrapper_main"
      >
        <div className="wrapper_table">
          {button && (
            <div className="table_button_wrapper">
              <div className="table_button_wrapper_left">
                {addLink && (
                  <div
                    onClick={() => {
                      dispatch(checkboxForDetailsOrDelete([]));
                      navigate(addLink.to);
                    }}
                    className={`table_button_create ${
                      checkedRowLength > 0 ? "disable" : ""
                    }`}
                  >
                    <AddIcon />
                    <NavLink to={addLink.to}>{addLink.label}</NavLink>
                  </div>
                )}

                {detailsLink && (
                  <div
                    onClick={() => {
                      if (checkedRowLength === 1) {
                        dispatch(checkboxForDetailsOrDelete(checkedRow));
                        navigate(detailsLink);
                      }
                    }}
                    className={`table_button ${
                      checkedRowLength === 1 ? "" : "disable"
                    }`}
                  >
                    <SeeIcon />
                    <span>Деталі</span>
                  </div>
                )}
                {deleteButton && (
                  <ModalDelete
                    submit={deleteTableFunc}
                    checkedRowLength={checkedRowLength}
                    checkedIds={checkedRow?.map(({ id }) => id)}
                  />
                )}
                {movedButton && (
                  <div
                    className={`table_button ${
                      checkedRowLength > 0 ? "" : "disable"
                    }`}
                    onClick={() => {
                      if (checkedRowLength > 0) {
                        dispatch(checkboxForDetailsOrDelete(checkedRow));
                        dispatch(setMovingChecked());
                        setcheckedRow([]);
                      }
                    }}
                  >
                    <FolderIcon />
                    <span>Перенести</span>
                  </div>
                )}
                {addCounerpartyButton && (
                  <div
                    onClick={() => {
                      if (checkedRowLength === 1) {
                        dispatch(checkboxForDetailsOrDelete(checkedRow));
                        navigate(addCounerpartyLink);
                      }
                    }}
                    className={`table_button_create ${
                      checkedRowLength === 1 ? "" : "disable"
                    }`}
                  >
                    <AddIcon />
                    <span style={{ color: "#fff" }}>Додати</span>
                  </div>
                )}
              </div>
              <div className="table_button_wrapper_right">
                {sellOrder && <ModalOrder sellOrder={sellOrder} />}
                {catalogReport && (
                  <div
                    onClick={() => {
                      if (checkedRowLength === 1) {
                        dispatch(checkboxForDetailsOrDelete(checkedRow));
                      }
                    }}
                    style={{ padding: "0" }}
                    className={`table_button ${
                      checkedRowLength === 1 ? "" : "disable"
                    }`}
                  >
                    <ModalReport />
                  </div>
                )}

                {dateRange && <DropDownCalendar />}
              </div>
            </div>
          )}

          {data && (
            <>
              <div
                className="wrapper_table_extra"
                style={
                  applicationsCatalog
                    ? {
                        maxHeight: "calc(100vh - 304px)",
                        minHeight: "calc(100vh - 304px)",
                      }
                    : {}
                }
              >
                {!applicationsCatalog && (
                  <button
                    style={{ color: "#fff", border: "none" }}
                    className={`table_button_create clearButton ${
                      checkedRowLength < 1 ? "disable" : ""
                    }`}
                    onClick={() => setcheckedRow([])}
                  >
                    Очистити
                  </button>
                )}

                <table>
                  <thead>
                    <tr>
                      <HeaderTable
                        dataInput={dataInput}
                        onChangeData={onChangeData}
                        columns={columns}
                      />
                    </tr>
                  </thead>
                  <tbody>
                    <BodyTable
                      data={data}
                      columns={columns}
                      toggleCheckedChanged={toggleCheckedChanged}
                      checkedRow={checkedRow}
                    />
                  </tbody>
                </table>
              </div>

              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={totalPages}
                pageSize={PageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </>
          )}
        </div>
        {applicationsCatalog && (
          <ApplicationCatalogue
            handleCounterApplication={handleCounterApplication}
            onChangeApplicationQuantity={onChangeApplicationQuantity}
            cancelItemTableFunc={cancelCheckedDataById}
            checkedRow={checkedRow}
            setcheckedRow={setcheckedRow}
            navigateToHomePage={navigateToHomePage}
            marginTopStyle={{ marginTop: "51px" }}
          />
        )}
      </div>
    </div>
  );
};

export default Table;
