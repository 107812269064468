import React from "react";

const Report = ({ data, counterparty, typeSell, number, date }) => {
  const handlePrint = () => {
    const printWindow = window.open("", "", "width=800,height=600");

    const htmlContent = `
      <html>
        <head>
          <title>Видаткова накладна № ${number}</title>
          <style>
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
            h2 {
              text-align: center;
            }
            p {
              margin: 5px 0;
            }
            .bold {
              font-weight: bold;
            }
            .signatures {
              margin-top: 20px;
              display: flex;
              justify-content: space-between;
            }
            .signatures div {
              width: 45%;
              text-align: center;
              border-top: 1px solid black;
            }
          </style>
        </head>
        <body>
          <h2>Видаткова накладна № ${number} від ${date}</h2>
          <p><span class="bold">Постачальник:</span> Дім фурнітури</p>
          <p><span class="bold">Отримувач:</span> ${counterparty}</p>
          <p><span class="bold">Вид торгівлі:</span> ${typeSell}</p>

          <table>
            <thead>
              <tr>
                <th>Назва товару</th>
                <th>Кількість</th>
                <th>Ціна</th>
                <th>Сума</th>
              </tr>
            </thead>
            <tbody>
              ${data
                .map(
                  (item) => `
                <tr>
                  <td>${item.name}</td>
                  <td>${item.quantity_application}</td>
                  <td>${item.price}</td>
                  <td>${item.price_sum}</td>
                </tr>
              `
                )
                .join("")}
            </tbody>
          </table>
          
          <p><span class="bold">Всього на суму:</span> ${data
            .reduce((acc, item) => acc + item.price_sum, 0)
            .toFixed(2)}</p>

          <p>Повернення або обмін товару здійснюється тільки за наявності розхідної накладної</p>
          <p>Ціни надані в рекламних цілях. Не є підставою для бухгалтерського обліку.</p>

          <div class="signatures">
            <div>Відвантажив(ла): _____________________</div>
            <div>Отримав(ла): _____________________</div>
          </div>
        </body>
      </html>
    `;

    printWindow.document.write(htmlContent);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  return (
    <div>
      <button
        style={{
          padding: "10px 20px",
          color: "#fff",
          border: "none",
          borderRadius: "4px",
          background: "#008947",
        }}
        onClick={handlePrint}
      >
        Печатать
      </button>
    </div>
  );
};

export default Report;
