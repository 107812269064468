import { useState, useEffect } from "react";
import UseModal from "../../../../UseModal";
import { ReactComponent as SettingSvg } from "../../../../../style/img/setting.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  createCategoryTable,
  deleteCatagory,
  getCategoryTable,
  updateCategory,
} from "../../../../../store/slices/tableSlice";
import ModalDelete from "../../ModalDelete";

const TableNavSetting = ({ category_name, parentSub_id, create = true }) => {
  const categoriesData = useSelector((state) => state.table.category);
  const dispatch = useDispatch();
  const [modalCreate, setModalCreate] = useState({
    visible: false,
    item: null,
  });
  const [name, setName] = useState("");
  const handleChange = (e) => {
    setName(e.target.value);
  };

  const [mainCagegory, setMainCagegory] = useState("");
  useEffect(() => {
    setMainCagegory(category_name);
  }, []);
  const closeModal = () =>
    setModalCreate(() => {
      return {
        item: null,
        visible: false,
      };
    });

  const onModal = () => {
    setModalCreate(() => {
      return {
        item: null,
        visible: true,
      };
    });
  };
  const submitCreate = async () => {
    if (name.length > 2) {
      const req = await dispatch(
        createCategoryTable({ name: name, parentSub_id: parentSub_id })
      );
      if (req.payload === 200) closeModal();
    }
  };

  const submitDelete = async (id) => {
    const req = await dispatch(deleteCatagory([id]));
    if (req.payload === 200) {
      dispatch(getCategoryTable());
    }
    return req;
  };

  return (
    <>
      <div className="tableNav_setting_button" onClick={() => onModal()}>
        <SettingSvg />
      </div>

      <UseModal
        visible={modalCreate.visible}
        title="Створити підкатегорії"
        content={
          <div className="modalCreate_wrapper">
            <span>Редагувати підкатегорію</span>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
              onSubmit={(e) => {
                e.preventDefault();
                if (mainCagegory.length > 2) {
                  dispatch(
                    updateCategory({ id: parentSub_id, name: mainCagegory })
                  );
                  console.log("id", parentSub_id);
                }
              }}
            >
              <input
                id="mainCagegory"
                value={mainCagegory}
                onChange={(e) => setMainCagegory(e.target.value)}
              />
              <button>Зберегти</button>
            </form>

            {create && (
              <>
                <span>Назва підкатегорії</span>
                <form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitCreate();
                  }}
                >
                  <input id="name" value={name} onChange={handleChange} />
                  <button>Додати підкатегорії</button>
                </form>
              </>
            )}

            <div
              className="modal_tableNav_buttonWrapper"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <button onClick={closeModal}>Cкасувати</button>
              <ModalDelete
                submit={submitDelete}
                checkedRowLength={1}
                checkedIds={parentSub_id}
              />
            </div>
          </div>
        }
        onClose={closeModal}
      />
    </>
  );
};

export default TableNavSetting;
