import React, { useEffect, useState } from "react";
import UseTable from "../../../useHook/useTable/UseTable";
import { useDispatch, useSelector } from "react-redux";
import { categoryData } from "./components/categoryData";
import { columnsTableStock } from "./components/columns";
import { makeUrl } from "../../../useHook/useTable/components/makeUrl";
import {
  getStockInvoiceByType,
  setActiveCategory,
  deleteStockInvoice,
} from "../../../store/slices/stockSlice";
// import axios, { API_URL } from "../../../axios";
// import { fetchEventSource } from "@microsoft/fetch-event-source";

const Stock = () => {
  const dispatch = useDispatch();
  const selectedCategory = useSelector((state) => state.table.selectedCategory);
  // const selectedCategory = useSelector((state) => state.stock.activeCategory);
  const tableData = useSelector((state) => state.stock.data);
  const totalPages = useSelector((state) => state.stock.total);
  //  state filters
  const [dataInput, setDataInput] = useState({
    id: "",
    user_name: "",
    counterparty_name: "",
  });
  // pagination
  const [PageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);

  const getDataStock = () => {
    if (categoryData.find(({ id }) => id === selectedCategory?.id)) {
      dispatch(
        getStockInvoiceByType({
          type: selectedCategory?.id,
          offset: currentPage === 1 ? 0 : currentPage * PageSize - PageSize,
          search: makeUrl(dataInput),
        })
      );
    }
  };
  useEffect(() => {
    getDataStock();
  }, [selectedCategory, currentPage, dataInput]);

  const deleteFunc = async (ids) => {
    const req = await dispatch(
      deleteStockInvoice({ ids, type: selectedCategory?.id })
    );
    if (req.payload === 200) {
      getDataStock();
    }
    return req;
  };

  useEffect(() => {
    dispatch(setActiveCategory(selectedCategory));
  }, [selectedCategory]);

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   const headers = {
  //     Authorization: `Bearer ${token}`,
  //   };
  //   const controller = new AbortController();
  //   const connectEventSource = async () => {
  //     await fetchEventSource(`${API_URL}/connect_stock`, {
  //       headers,
  //       signal: controller.signal,
  //       async onopen(response) {
  //         if (response.status === 401) {
  //           const response = await axios.get(`${API_URL}/refresh`, {
  //             withCredentials: true,
  //           });
  //           localStorage.setItem("token", response.data.acsses);
  //           // connectEventSource();
  //         }
  //       },
  //       onmessage() {
  //         getDataStock();
  //       },
  //     });
  //   };
  //   connectEventSource();
  //   return () => controller.abort();
  // }, [selectedCategory, currentPage]);

  // input onChannge
  const onChangeData = (name, data) => {
    setDataInput((dataInput) => ({
      ...dataInput,
      [name]: data,
    }));
  };

  return (
    <UseTable
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      PageSize={PageSize}
      totalPages={totalPages}
      table_nav={true}
      data_nav={categoryData}
      data={tableData}
      columns={columnsTableStock}
      onChangeData={onChangeData}
      dataInput={dataInput}
      tableNavLebel={"Склад"}
      addLink={{ to: "/stock/create", label: "Додати запис" }}
      detailsLink={"/stock/details"}
      movedButton={false}
      deleteFunc={deleteFunc}
      dateRange={true}
    />
  );
};

export default Stock;
