import React from "react";
import { useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import ApplicationCatalogue from "../../../../useHook/useTable/components/applicationCatalogue/ApplicationCatalogue";
import button_back from "../../../../style/img/button_back.png";

const StockDetails = () => {
  const navigate = useNavigate();
  const data = useSelector(
    (state) => state.table.checkboxForDetailsOrDelete
  )[0];
  const selectedCategory = useSelector((state) => state.stock.activeCategory);

  const invoicesItems =
    data && selectedCategory?.id === "booking"
      ? data?.BookingItems.map(({ quantity, ...item }) => ({
          quantity_application: quantity,
          price_sum: item?.StockItem?.Product?.price * quantity,
          ...item,
        }))
      : data?.StockTransactionItems.map(({ quantity, ...item }) => ({
          quantity_application: quantity,
          price_sum: item?.StockItem?.Product?.price * quantity,
          ...item,
        }));
  // console.log(invoicesItems);
  const invoicesCounterparty = data && data.Counterparty;

  return (
    <div className="purchaseCreate">
      <div className="purchaseCreate_head">
        <img onClick={() => navigate("/stock")} src={button_back} alt="back" />
        <span>Накладна</span>
      </div>
      <Formik
        initialValues={{ comment: data.comment }}
        validationSchema={Yup.object({
          // password: Yup.string().required("Enter password!"),
          // login: Yup.string().required("Enter login!"),
        })}
        onSubmit={async (body) => {}}
      >
        <Form className="">
          <div className="catalogueCreate_input_top_wrapper">
            <div className="catalogueCreate_input_top">
              <div className="catalogueCreate_input_top_first">
                <div style={{ width: "25%" }}>
                  <span>Постачальники</span>
                  <div style={{ display: "flex" }}>
                    <span className="purchaseCounterpary">
                      {invoicesCounterparty && invoicesCounterparty.name}
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
            <div className="catalogueCreate_input_top_textarea">
              <span>Коментар</span>
              <Field disabled as="textarea" type="text" name="comment" />
            </div>
          </div>
          <ApplicationCatalogue
            handleCounterApplication={() => {}}
            onChangeApplicationQuantity={() => {}}
            cancelItemTableFunc={() => {}}
            checkedRow={invoicesItems}
            setcheckedRow={() => {}}
            styleTable={{
              minHeight: "calc(100vh - 400px)",
              maxHeight: "calc(100vh - 400px)",
              padding: "0",
            }}
            button={false}
            toggle={false}
          />
          <div className="purchaseCreate_buttonWrapper">
            <button className="catalogueCreate_submit" type="submit">
              Додати
            </button>
            <button
              onClick={() => navigate("/stock/create/catalouge")}
              className="catalogueCreate_submit"
            >
              За каталогом
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default StockDetails;
