import React, { useEffect, useState } from "react";
import ModalTableNavCreate from "./components/ModalTableNavCreate";
import RenderMenuItem from "./components/RenderMenuItem";
import search_icon from "../../../../style/img/button_search.png";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedArrayCategory,
  setSelectedCategoryStore,
  setMovingChecked,
  checkboxForDetailsOrDelete,
} from "../../../../store/slices/tableSlice";
import { setCategoryProducts } from "../../../../store/slices/productSlice";

import "./tableNav.css";

const TableNav = ({
  data_nav,
  tableNavLebel,
  createNewCategory,
  table_nav_search,
}) => {
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState();
  const selectedCategoryStore = useSelector(
    (state) => state.table.selectedCategory
  );

  const categoryStore = useSelector((state) => state.table.category);

  const selectedStockCategory = useSelector(
    (state) => state.stock.activeCategory
  );

  const selectedPaymentCategory = useSelector(
    (state) => state.payment.activeCategory
  );

  // const findTreeRootAndIdsByTargetId = (categories, targetId) => {
  //   let categoryIds = [];
  //   const findCategory = (category, stopAfterTarget = false) => {
  //     const currentCategory = { ...category, subMenu: [] };
  //     if (category.id === targetId) {
  //       categoryIds.push(currentCategory.id);
  //       return true;
  //     }
  //     if (category.subMenu) {
  //       for (const subCategory of category.subMenu) {
  //         const found = findCategory(subCategory, stopAfterTarget);
  //         if (found) {
  //           if (!stopAfterTarget) {
  //             currentCategory.subMenu.push(found);
  //           }
  //           categoryIds.push(category.id);
  //           return true;
  //         }
  //       }
  //     }

  //     return false;
  //   };

  //   for (const category of categories) {
  //     if (findCategory(category, true)) {
  //       break;
  //     }
  //   }

  //   return categoryIds;
  // };

  function findCategoryById(categories, categoryId) {
    for (const category of categories) {
      if (category.id === categoryId) {
        return category;
      }

      if (category.subMenu && category.subMenu.length > 0) {
        const subMenuResult = findCategoryById(category.subMenu, categoryId);
        if (subMenuResult) {
          return subMenuResult;
        }
      }
    }

    return null;
  }

  useEffect(() => {
    if (
      selectedCategoryStore &&
      findCategoryById(data_nav, selectedCategoryStore?.id)
    ) {
      setSelectedCategory(selectedCategoryStore);
    } else {
      setSelectedCategory(data_nav[0]);
    }
    // for stock hash active route
    if (
      selectedStockCategory &&
      findCategoryById(data_nav, selectedStockCategory?.id)
    ) {
      return setSelectedCategory(selectedStockCategory);
    }
    // for payment hash active route
    if (
      selectedPaymentCategory &&
      findCategoryById(data_nav, selectedPaymentCategory?.id)
    ) {
      return setSelectedCategory(selectedPaymentCategory);
    }
  }, [categoryStore]);

  // useEffect(() => {
  //   if (tableNavLebel === "Каталог" || tableNavLebel === "Catalogue") {
  //     dispatch(
  //       setSelectedArrayCategory(
  //         findTreeRootAndIdsByTargetId(data_nav, selectedCategory)
  //       )
  //     );
  //   } else {
  //     dispatch(setSelectedArrayCategory([]));
  //   }
  // }, [selectedCategory]);

  useEffect(() => {
    if (
      (tableNavLebel === "Каталог" || tableNavLebel === "Catalogue") &&
      data_nav &&
      selectedCategoryStore &&
      findCategoryById(data_nav, selectedCategoryStore.id)
    ) {
      if (selectedCategoryStore.parentSub_id) {
        dispatch(
          setSelectedArrayCategory([selectedCategoryStore.parentSub_id])
        );
        setSelectedCategory(selectedCategoryStore);
      } else {
        dispatch(setSelectedArrayCategory([selectedCategoryStore.id]));

        if (selectedCategoryStore.subMenu) {
          setSelectedCategory(selectedCategoryStore.subMenu[0]);
        } else {
          setSelectedCategory(selectedCategoryStore);
        }
      }
    } else {
      if (data_nav) {
        dispatch(setSelectedArrayCategory([data_nav[0]?.id]));
        if (data_nav[0]?.subMenu) {
          setSelectedCategory(data_nav[0]?.subMenu[0]);
        }
      } else {
        setSelectedCategory(data_nav[0]);
      }
    }
  }, [categoryStore]);

  useEffect(() => {
    if (selectedCategory) {
      dispatch(setSelectedCategoryStore(selectedCategory));
    }
  }, [selectedCategory]);
  const onSelect = (id) => {
    setSelectedCategory(id);
  };

  const movingChecked = useSelector((state) => state.table.movingChecked);
  const checkedProducts = useSelector(
    (state) => state.table.checkboxForDetailsOrDelete
  );
  const movingProductToCategory = (category_id) => {
    dispatch(
      setCategoryProducts({
        category_id,
        checkedProducts: checkedProducts.map(({ id }) => id),
      })
    );
    dispatch(setMovingChecked());
    dispatch(checkboxForDetailsOrDelete([]));
  };

  return (
    <div className="table_sidenav">
      <div>
        <div className="table_sidenav_top">
          <span>{tableNavLebel}</span>
          {table_nav_search && <img src={search_icon} alt="search icon" />}
        </div>
        <nav className="table_navbar">
          {data_nav &&
            data_nav.map((menuItem) => (
              <RenderMenuItem
                key={menuItem.id}
                menuItem={menuItem}
                onSelect={onSelect}
                selectedCategory={selectedCategory}
                createNewCategory={createNewCategory}
                movingChecked={movingChecked}
                movingProductToCategory={movingProductToCategory}
              />
            ))}
        </nav>
      </div>
      <ModalTableNavCreate createNewCategory={createNewCategory} />
    </div>
  );
};

export default TableNav;
