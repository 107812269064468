import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { createStockInvoiceByType } from "../../../../store/slices/stockSlice";
import {
  handleCounterCheckboxData,
  cancelItemCheckboxData,
  checkboxForDetailsOrDelete,
  setCheckboxData,
  onChangeCheckboxData,
} from "../../../../store/slices/tableSlice";
import AddCounterpartyButton from "../../counterparty/components/AddCounterpartyButton";
import ApplicationCatalogue from "../../../../useHook/useTable/components/applicationCatalogue/ApplicationCatalogue";

import button_back from "../../../../style/img/button_back.png";

const StockCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedCategory = useSelector((state) => state.stock.activeCategory);

  const checkedRow = useSelector((state) => state.table.checkboxData);
  const checkedCounterparty = useSelector(
    (state) => state.table.checkboxForDetailsOrDelete
  )[0];

  const toggleRedaxData = (accessor, id) => {
    dispatch(handleCounterCheckboxData(accessor, id));
  };
  const onChangeApplicationQuantityRedaxData = ({ value, id }) => {
    dispatch(onChangeCheckboxData({ value, id }));
  };
  const cancelItemRedaxData = (id) => {
    dispatch(cancelItemCheckboxData(id));
  };

  return (
    <div className="purchaseCreate">
      <div className="purchaseCreate_head">
        <img onClick={() => navigate("/stock")} src={button_back} alt="back" />
        <span>Накладна</span>
      </div>
      <Formik
        initialValues={{}}
        validationSchema={Yup.object({
          // password: Yup.string().required("Enter password!"),
          // login: Yup.string().required("Enter login!"),
        })}
        onSubmit={async (body) => {
          const data = body;
          data.items = checkedRow.map(({ id, quantity_application }) => ({
            id,
            quantity: quantity_application,
          }));
          data.counterparty_id = checkedCounterparty?.id;

          data.type = selectedCategory.id;

          const req = await dispatch(createStockInvoiceByType(data));

          if (req.payload === 200) {
            dispatch(setCheckboxData([]));
            dispatch(checkboxForDetailsOrDelete([]));
            navigate("/stock");
          }
        }}
      >
        <Form className="">
          <div className="catalogueCreate_input_top_wrapper">
            <div className="catalogueCreate_input_top">
              <div className="catalogueCreate_input_top_first">
                <AddCounterpartyButton
                  checkedCounterparty={checkedCounterparty}
                  title={"Контрагент"}
                  navigateTo={"/stock/create/search"}
                />

                <div></div>
              </div>
            </div>
            <div className="catalogueCreate_input_top_textarea">
              <span>Коментар</span>
              <Field as="textarea" type="text" name="comment" />
            </div>
          </div>
          <ApplicationCatalogue
            handleCounterApplication={toggleRedaxData}
            onChangeApplicationQuantity={onChangeApplicationQuantityRedaxData}
            cancelItemTableFunc={cancelItemRedaxData}
            checkedRow={checkedRow}
            setcheckedRow={() => {}}
            styleTable={{
              minHeight: "calc(100vh - 400px)",
              maxHeight: "calc(100vh - 400px)",
              padding: "0",
            }}
            button={false}
          />
          <div className="purchaseCreate_buttonWrapper">
            <button className="catalogueCreate_submit" type="submit">
              Додати
            </button>
            <button
              onClick={() => navigate("/stock/create/catalouge")}
              className="catalogueCreate_submit"
            >
              За каталогом
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default StockCreate;
